/* eslint-disable react/prop-types */
import { GTM_PAYLOAD_SCRIPT } from "@constants";
import breadcrumbSchema from "@jsonLdScripts/breadcrumbSchema";
import Head from "next/head";
import { useRouter } from "next/router";

const GtmLandingPages = () => {
  const nextRouter = useRouter();

  return (
    <Head>
      <script dangerouslySetInnerHTML={{ __html: GTM_PAYLOAD_SCRIPT }} />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={breadcrumbSchema(nextRouter)}
        key="landing-breadcrumb-jsonld"
      />
    </Head>
  );
};

export default GtmLandingPages;
