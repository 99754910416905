/* eslint-disable camelcase */
import { TWpConsult, TWpGlobal } from "#types/wordPress";
import { TEndpoint } from "@@types/api.core";
import Api from "@core/api.core";
import { AxiosRequestConfig } from "axios";
import getConfig from "next/config";
import { mergeRight } from "ramda";

const { wpApiHost } = getConfig().publicRuntimeConfig;

export class PostsRepository extends Api {
  protected readonly endpoints: TEndpoint = {
    posts: {
      allPosts: "/wp/v2/posts",
      postBySlug: "/wp/v2/posts",
      postByCategory: "/wp/v2/posts?categories={category}",
      postPerPage: "/wp/v2/posts?per_page={postsPerPage}&page={currentPage}&categories={category}",
      postSeoInfo: `/rankmath/v1/getHead?url=${wpApiHost}/{slug}`
    }
  };

  constructor(baseOptions: AxiosRequestConfig = {}) {
    super(mergeRight({ baseURL: `${wpApiHost}/wp-json` }, baseOptions));
  }

  async getAllPosts(): Promise<TWpGlobal[]> {
    return this.get<TWpGlobal[]>(this.endpoints.posts.allPosts).then((response) => response.data);
  }

  async getPostBySlug(slug: string): Promise<TWpGlobal> {
    return this.get<TWpGlobal>(this.endpoints.posts.postBySlug, {
      params: { slug }
    }).then((response) => response.data);
  }

  async getPostPerPage(postsPerPage: number, currentPage: number, category: number): Promise<TWpConsult> {
    const endpoint = this.endpoints.posts.postPerPage
      .replace("{postsPerPage}", postsPerPage.toString())
      .replace("{currentPage}", currentPage.toString())
      .replace("{category}", category.toString());
    const response = await this.get<TWpConsult>(endpoint);
    return {
      data: response.data,
      headers: response.headers
    };
  }

  async getPostByCategory(category: number): Promise<TWpConsult> {
    const endpoint = this.endpoints.posts.postByCategory.replace("{category}", category.toString());
    const response = await this.get<TWpConsult>(endpoint);
    return {
      data: response.data,
      headers: response.headers
    };
  }

  async getSeoPost(slug: string): Promise<string> {
    const endpoint = this.endpoints.posts.postSeoInfo.replace("{slug}", slug.toString());
    return this.get<string>(endpoint).then((response) => response.data);
  }
}

export default new PostsRepository();
