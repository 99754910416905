import ContainerModal from "@components/containerModalRegister/component";
import DynamicPopUp from "@components/cookies/dynamicPopUp/component";
import MagnetoLoginHeaderComponent from "@components/layouts/layoutB2C/headers/magnetoLoginHeader.component";
import MagnetoLogoutHeaderComponent from "@components/layouts/layoutB2C/headers/magnetoLogoutHeader.component";
import { faviconB2c } from "@constants";
import { AuthContext } from "@context";
import { EDeviceTypes } from "@enums/filter.core.enum";
import useMagnetoFooter from "@hooks/magnetoUi/useMagnetoFooter";
import { Footer, MagnetoUIProvider } from "magneto365.ui";
import { TFunction, withTranslation } from "next-i18next";
import { useRouter } from "next/router";
import React, { Fragment, useContext } from "react";
import Favicon from "../favicon/component";
import styles from "./layout.module.scss";
import Resolution0070 from "./resolution0070/component";
import ParameterOriginChecker from "./parameterOriginChecker";

type Props = {
  t: TFunction;
  children: React.ReactNode;
  device?: EDeviceTypes;
};

const LayoutB2c = ({ children, device, t }: Props) => {
  const { isAuthenticated } = useContext(AuthContext);
  const { footerProps } = useMagnetoFooter({ t, isAuthenticated });
  const router = useRouter();
  const { rejectCookies } = router.query;
  const hasCookiesParam = rejectCookies !== undefined;

  return (
    <Fragment>
      <Favicon favicon={faviconB2c} />

      <ParameterOriginChecker parameter="app">
        <MagnetoUIProvider device={device}>
          {isAuthenticated ? <MagnetoLoginHeaderComponent /> : <MagnetoLogoutHeaderComponent />}
        </MagnetoUIProvider>
      </ParameterOriginChecker>

      <main className={styles.main}>{children}</main>

      <ContainerModal />
      {/* <DynamicPopUp device={device} /> */}

      <ParameterOriginChecker parameter="app">
        {hasCookiesParam ? null : <DynamicPopUp device={device} />}

        <MagnetoUIProvider device={device}>
          <Footer {...footerProps} />
          <Resolution0070 />
        </MagnetoUIProvider>
      </ParameterOriginChecker>
    </Fragment>
  );
};

export default withTranslation()(LayoutB2c);
