export const logoB2b = "/es/magneto-b2b-white.svg";
export const logoB2bBusiness = "/es/magneto-b2b-business-white.svg";
export const faviconB2b = "/es/favicon-b2b.ico";
export const faviconB2c = "/es/favicon.ico";
export const iconCreateAccount = "https://static.magneto365.com/lib/assets/a35333dc3f36808a.svg";
export const multimediaDomain = "https://static.magneto365.com/wordpress";
export const imgDefault = "https://static.magneto365.com/wordpress/2023/11/29083743/magnetoglobal.webp";
export const arrowIcon = "https://static.magneto365.com/wordpress/2024/02/29091741/arrow.svg";
export const FacebookIcon = "/es/assets/icons/Facebook.svg";
export const InstagramIcon = "/es/assets/icons/Instagram.svg";
export const LinkedinIcon = "/es/assets/icons/Linkedin.svg";
export const YoutubeIcon = "/es/assets/icons/Youtube.svg";
export const TiktokIcon = "/es/assets/icons/Tiktok.svg";
export const WhatsappIcon = "/es/assets/icons/Whatsapp.svg";
