import { productionHost } from "./environment.constant";

export const FILTER_API =
  "?_fields=id,slug,status=publish,title,content,template,acf,uagb_featured_image_src,featured_media,parent,categories,excerpt";

export const FILTER_API_MENU = "&_fields=id,title,url,parent,target";

// ----------------------------------------

export const MANAGER_MENUS = [
  { name: "Menu Soluciones", id: 41 },
  { name: "Menu Negocios", id: 32 }
];

export const SEO_POLICIES = "politicas";
export const SEO_HOME = "home";
export const SEO_HOME_V2 = "home-b2c-v2";
export const SEO_BLOG = "blog";
export const SEO_BLOG_BUSINESS = "soluciones/blog-empresas";
export const SEO_FAQ = "preguntas-frecuentes";
export const SEO_COMPANY = "soluciones";
export const SEO_DIGITAL_SELECTION = "soluciones/seleccion-digital";
export const SEO_INTEGRAL_EVALUATION = "soluciones/evaluacion-integral";
export const SEO_ADME = "soluciones/seleccion-digital/agencia-de-marca-empleadora";
export const SEO_BUSINESS = "soluciones/negocios";
export const SEO_FAQ_BUSINESS = "soluciones/negocios/preguntas-frecuentes/";
export const SEO_PLANS_BUSINESS = "soluciones/negocios/planes/";

export const HOME_PAGE_ID = 141;
export const HOME_PAGE_V2_ID = 124417;
export const FAQ_PAGE_ID = 118012;
export const POLICIES_PARENT_ID = 120273;
export const COMPANY_ID = 121519;
export const DIGITAL_SELECTION_PAGE_ID = 121861;
export const INTEGRAL_EVALUATION_PAGE_ID = 121554;
export const ADME_PAGE_ID = 121728;
export const BUSINESS_PAGE_ID = 119461;
export const FAQ_BUSINESS_PAGE_ID = 119710;

export const BLOG_BUSINESS_PAGE_ID_DEV = 126146;
export const BLOG_BUSINESS_PAGE_ID_PROD = 125920;

export const PLANS_BUSINESS_PAGE_ID_DEV = 126160;
export const PLANS_BUSINESS_PAGE_ID_PROD = 125922;

export const SLIDER_HOME = 75; //CATEGORY "Slider - home b2c"
export const POST_TYPE_UPDATE = 78;

let BLOG_BUSINESS_PAGE_ID: number;
let PLANS_BUSINESS_PAGE_ID: number;

if (productionHost) {
  BLOG_BUSINESS_PAGE_ID = BLOG_BUSINESS_PAGE_ID_PROD;
  PLANS_BUSINESS_PAGE_ID = PLANS_BUSINESS_PAGE_ID_PROD;
} else if (productionHost == false) {
  BLOG_BUSINESS_PAGE_ID = BLOG_BUSINESS_PAGE_ID_DEV;
  PLANS_BUSINESS_PAGE_ID = PLANS_BUSINESS_PAGE_ID_DEV;
}

export { BLOG_BUSINESS_PAGE_ID, PLANS_BUSINESS_PAGE_ID };
